<template>
  <div class="contact position-relative">
    <section class="section-shaped my-0">
                <div class="shape shape-style-1 shape-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="text-white container shape-container d-flex">
                <div class="col-sm-6"><strong>DrFix - XiaomiService Hellas</strong><br>
              <address>{{ $t('addr') }}</address>
                            <a href="https://www.google.com/maps/place/DrFix+Service+%CE%91%CE%BD%CF%84%CE%B1%CE%BB%CE%BB%CE%B1%CE%BA%CF%84%CE%B9%CE%BA%CE%AC+%CE%9A%CE%B9%CE%BD%CE%B7%CF%84%CE%AC+%CE%A5%CF%80%CE%BF%CE%BB%CE%BF%CE%B3%CE%B9%CF%83%CF%84%CE%AD%CF%82/@37.9655347,23.6478409,17z/data=!4m13!1m7!3m6!1s0x14a1bb9eabc521e3:0xbdefe78e74b6de84!2zN863z4IgzpzOsc-Bz4TOr86_z4UgMTk0NCA3NSwgzp3Or866zrHOuc6xIDE4NCA1MA!3b1!8m2!3d37.9655347!4d23.6500296!3m4!1s0x14a1bba0a4ef3933:0x622965cab60d74e9!8m2!3d37.9655347!4d23.6500296" target="_blank" class="btn btn-info"><i class="fa fa-map-marker"></i>{{ $t('map') }}</a>
                          <br><br><strong>{{ $t('title_bus') }}</strong><br>
              <img src="img/icons/bus.png"><span v-html="$t('bus1')"></span><br>
              {{ $t('bus11') }}<br>
              <span v-html="$t('trol1')"></span><br>
              {{ $t('trol11') }}<br>
              <img src="img/icons/metro.png"> <b>{{ $t('metro1') }}</b> <br>{{ $t('metro11') }} <br><br>
</div>
<div class="col-sm-5">
              <img height="20" src="img/icons/tel.png"> <strong>{{ $t('tele') }}</strong><br>
              <a href="tel:+302170001340"><b>217 000 1340</b></a><br>
              <img height="20" src="img/icons/mail.png"> <strong>{{ $t('sndmail') }}</strong><br>
              <a href="mailto:contact@xiaomiservice.gr">contact@xiaomiservice.gr</a> <br>
              <img height="20" src="img/icons/messenger.png"> <strong>{{ $t('fbmessr') }}</strong><br>
              <a href="https://m.me/DrFixService">Στείλε μήνυμα</a><br>
              <br>
</div>
</div>
            </section>

<div class="col-12">
              <br><h2>{{ $t('formtitle') }}</h2>
</div>
            <div class="container-md">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <form class="contact-form" @submit.prevent="sendEmail">
                        <input type="text" :placeholder="$t('name') "  name="name">
                        <input :placeholder="$t('emai')" type="email" name="email">
                        <input :placeholder="$t('tele')" type="text" name="telephone">
                        <textarea :placeholder="$t('mess')"  name="message"></textarea>
                        <input type="submit" :value="$t('send')">
                        </form>
                    </div>
                </div>
            </div>
    
  </div>
</template>

<script>import emailjs from 'emailjs-com';

export default {
name: 'Contact',
  data() {
    return {
      name: '',
      email: '',
      telephone: '',
      message: ''
    }
  },

  methods: {
    sendEmail: (e) => {
      emailjs.sendForm('service_ds9j30i', 'template_welfwrr', e.target, 'user_JxWOgZqHQiqlrutBXdNjB', {
          name: self.name,
          email: self.email,
          telephone: self.telephone,
          message: self.message
        })
        .then((result) => {
            console.log('SUCCESS!', result.status, result.text);
        }, (error) => {
            console.log('FAILED...', error);
        });
      self.name = ''
      self.email = ''
      self.message = ''
      alert('Το μήνυμά σας απεστάλη ');
    }
  }
}
</script>

<style scoped>
* {box-sizing: border-box;}

.container-md {
  display: block;
  margin:auto;
  text-align: center;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 5px;
  width: 90%;
}

a {color: black;}


input[type=text], [type=email], textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=submit] {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}
</style>

<i18n>
{
  "en": {
    "name": "Name",
     "emai": "Email",
     "tele": "Telephone",
     "sndmail": "Email",
     "fbmessr": "Facebook Messenger",
     "mess": "Mobile phone model and what is the problem",
     "send": "Send",
     "addr": "7is Martiou 75, Nikaia 18450",
     "map": " Google Maps",
     "title_bus": "Access by Public Transport",
     "bus1": "<b>841</b>, <b>846</b>, <b>820</b>, <b>827</b>, <b>828</b>, <b>800</b>, <b>809</b>, <b>909</b>",
     "bus11": "Stop «AG.NIKOLAOS - ST.NIKAIA»",
     "bus2": "<b>828</b>, <b>909</b>",
     "bus22": "From Pireas Stop «SXOLEIO»",
     "bus3": "<b>830</b>, <b>831</b>, <b>810</b>, <b>828</b>",
     "bus33": "To Pireas Stop «GEFYRA»",
     "trol1": "<b>Trolley 21</b>",
     "trol11": "Stop «NIKAIA»",
     "metro1": "Metro Line 3 (Blue)",
     "metro11": "Station Nikaia (3 mins walk)",
     "formtitle": "Send us a message to learn about the repair you need"
  },
  "el": {
     "name": "Όνομα",
     "emai": "Email",
     "tele": "Τηλέφωνο",
     "sndmail": "Email",
     "fbmessr": "Facebook Messenger",
     "mess": "Μοντέλο κινητού και τι πρόβλημα έχει",
     "send": "Αποστολή",
     "addr": "7ης Μαρτίου 75, Νίκαια 18450",
     "map": " Χάρτης Google",
     "title_bus": "Πρόσβαση με συγκοινωνία",
     "bus1": "<b>841</b>, <b>846</b>, <b>820</b>, <b>827</b>, <b>828</b>, <b>800</b>, <b>809</b>, <b>909</b>",
     "bus11": "Στάση «ΣΤ. ΝΙΚΑΙΑ - ΑΓ. Νικόλαος»",
     "bus2": "<b>828</b>, <b>909</b>",
     "bus22": "Από Πειραιά Στάση «ΣΧΟΛΕΙΟ»",
     "bus3": "<b>830</b>, <b>831</b>, <b>810</b>, <b>828</b>",
     "bus33": "Προς Πειραιά Στάση «ΓΕΦΥΡΑ»",
     "trol1": "<b>Τρόλεϋ 21</b>",
     "trol11": "Στάση «ΝΙΚΑΙΑ»",
     "metro1": "Μετρό Γραμμή 3 (Μπλε)",
     "metro11": "Στάση Νίκαια (3 λεπτά περπάτημα)",
     "formtitle": "Στείλτε μας ένα μήνυμα για να μάθετε για την επισκευή που χρειάζεστε"
}
}
</i18n>
