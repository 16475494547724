<template>
  <div class="position-relative">
    <section class="section-shaped my-0">
                <div class="shape shape-style-1 shape-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-8">
                                <h1 class="display-3  text-white">{{ $t('title_rep') }}</h1>
                                <p class="lead  text-white">
                                <p class="lead  text-white" v-html="$t('odhgies1')"></p>
                                <p class="lead  text-white" v-html="$t('odhgies2')"></p>
                                <span class="lead  text-white" v-html="$t('odhgies3')"></span>
                                <span class="lead  text-white" v-html="$t('odhgies4')"></span>
                                <span class="lead  text-white" v-html="$t('odhgies5')"></span>
                                <p class="lead  text-white" v-html="$t('odhgies6')"></p>
                                <p class="lead  text-white" v-html="$t('odhgies7a')"></p>
                                <span class="lead  text-black" v-html="$t('addr1')"></span>
                                <p class="lead  text-white" v-html="$t('odhgies7b')"></p>
                                <span class="lead  text-black" v-html="$t('addr2')"></span>
                                
</div>
<div class="col-lg-4">
<p class="lead  text-white"><span>{{ $t('odhgies8') }}
</span></p>
	<p class="lead  text-white">{{ $t('terms1') }} <a href="https://drfix.gr/eggyhsh-episkevwn" target="_blank">{{ $t('terms2') }}</a>.</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section></div>
</template>

<script>

export default {
name: 'Repair'
    }

</script>

<style scoped>

</style>

<i18n>
{
  "en": {
    "title_rep": "How to send your device for repairing",
    "odhgies1" : "Please pack the device or devices that you will send in a safe box so that they are not damaged.",
    "odhgies2" : "Leave a piece of paper in the box where you will describe:",
    "odhgies3" : "1) the problem and the reason why you sent the device in detail, as well as any code or pattern required to unlock the device. Without being able to unlock it, the device is assembled and shipped at your own risk and without full warranty. <br>",
    "odhgies4" : "2) Your contact details and return address. <br>",
    "odhgies5" : "3) The desired method of payment .<br>",
    "odhgies6" : "Important: Turn off any alarms if possible. If possible, make sure that the device is adequately charged to complete the repair faster. Do not leave SIM cards and memory cards, cases, chargers and anything else unnecessary. ",
    "odhgies7a": "1) Send by <a href='https://steiledema.boxnow.gr/apostoli-dematos' target='_blank'>Box Now by clicking here</a> to the following Contact details (Cost from 2€):",
    "addr1": "DrFix<br>Locker: Μαδύτου 25, 18450 (Market ΠΟΛΙΤΗΣ - Νίκαια) or 25ης Μαρτίου 81 & Μουρούζη, 18543 (ΑΒ Κοκκινιάς) or Αγρινίου & Πύργου, Πειραιάς, 18531 (Αγρινίου & Πύργου - Δήμος Πειραιά)<br>email: info@drfix.gr (Important, so we are notified to receive it)<br>6995336804",
	"odhgies7b": "2) Send by Geniki Taxydromiki (<a href='https://www.taxydromiki.com/find-store' target='_blank'>check here where is the nearest courier store in your area</a>) with recipient charge (you pay for the shipping along with the repair and it is of total 6.6€ within Athens, 7.6€ for the rest of Greece) in the data:",
    "addr2": "DrFix<br>Gen. Taxydromiki Nikaias<br>NIKAIA 18450<br>2170001340",
    "odhgies8": "In case of shipment by another courier, you will have to pay for the shipping and inform us in advance with the shipment number. ",
    "terms1": "By sending a repair, you accept the ",
    "terms2": "repair terms"
     
  },
  "el": {
     "title_rep": "Τρόπος αποστολής συσκευής προς επισκευή",
    "odhgies1" : "Παρακαλούμε πακετάρετε τη συσκευή ή τις συσκευές που θα στείλετε, σε κάποιο ασφαλές κουτί ώστε να μην πάθουν ζημιά.",
    "odhgies2" : "Αφήστε μέσα στο κουτί, ένα χαρτί όπου θα περιγράφετε:",
    "odhgies3" : "1) το πρόβλημα και τον λόγο για τον οποία στείλατε τη συσκευή αναλυτικά, καθώς και τυχόν κωδικό ή μοτίβο που απαιτείται για το ξεκλείδωμα της συσκευής. Χωρίς τον κωδικό, η συσκευή συναρμολογείται και στέλνεται με δική σας ευθύνη και χωρίς πλήρη εγγύηση.<br>",
    "odhgies4" : "2) Τα στοιχεία σας για επικοινωνία και τη διεύθυνση επιστροφής.<br>",
    "odhgies5" : "3) Τον επιθυμητό τρόπο πληρωμής.<br>",
    "odhgies6" : "Σημαντικό: Απενεργοποιήστε εφόσον είναι εφικτό τυχόν ξυπνητήρια. Φροντίστε εάν γίνεται, η συσκευή να είναι επαρκώς φορτισμένη, για ταχύτερη διεκπεραίωση της επισκευής. Μην αφήνετε μέσα κάρτες σιμ και κάρτες μνήμης, θήκες, φορτιστές και οτιδήποτε άλλο περιττό.",
    "odhgies7a": "1) Στείλτε το με <a href='https://steiledema.boxnow.gr/apostoli-dematos' target='_blank'>Box Now πατώντας εδώ</a> στα στοιχεία (Κόστος από 2€):",
    "addr1": "DrFix<br>Locker: Μαδύτου 25, 18450 (Market ΠΟΛΙΤΗΣ - Νίκαια) or 25ης Μαρτίου 81 & Μουρούζη, 18543 (ΑΒ Κοκκινιάς) or Αγρινίου & Πύργου, Πειραιάς, 18531 (Αγρινίου & Πύργου - Δήμος Πειραιά)<br>email: info@drfix.gr (Απαραίτητο για να ενημερωθούμε για την παραλαβή)<br>6995336804",
	"odhgies7b": "2) Στείλτε το με Γενική ταχυδρομική (<a href='https://www.taxydromiki.com/find-store' target='_blank'>δείτε εδώ ποιο κατάστημα courier σας εξυπηρετεί</a>) ι χρέωση δική μας (τα μεταφορικά τα πληρώνετε μαζί με την επισκευή και είναι 6.6€ πηγαινέλα για εντός Αθήνας, 7.6€ για την υπόλοιπη Ελλάδα) στα στοιχεία:",
    "addr2": "DrFix<br>Γεν. Ταχυδρομική Νίκαιας<br>ΝΙΚΑΙΑ 18450<br>2170001340",
    "odhgies8": "Σε περίπτωση αποστολής με άλλη κούριερ, θα πρέπει να πληρώσετε εσείς τα μεταφορικά και να μας ενημερώσετε εκ των προτέρων με τον αριθμό αποστολής.",
    "terms1": "Με την αποστολή κάθε επισκευής, αποδέχεστε τους ",
    "terms2": "όρους επισκευών"
}
}
</i18n>
